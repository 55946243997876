import {FC, useCallback} from 'react';
import {styled} from 'styled-components';
import {useParams} from 'wouter';

import {randomStringUnsafe} from '@shared/lib/random_utils';

import {Button, NavLink} from '@shared-web/components/core/button';

import {Portfolio} from '@src/components/portfolio';
import {updatePortfolios, usePortfolios} from '@src/lib/portfolio';

export const HomePage: FC = () => {
  const portfolios = usePortfolios();
  const {portfolioId} = useParams<{portfolioId?: string}>();
  const pId = portfolioId ?? portfolios[0]?.id;

  const handleCreateClick = useCallback(() => {
    updatePortfolios(current => [
      ...current,
      {id: randomStringUnsafe(10), label: `Portfolio #${current.length + 1}`, stocks: []},
    ]);
  }, []);

  return (
    <Wrapper>
      <PortfolioTabs>
        {portfolios.map(p => (
          <PortfolioNavLink $selected={pId === p.id} key={p.id} to={`/portfolio/${p.id}`}>
            {p.label}
          </PortfolioNavLink>
        ))}
        <Button onClick={handleCreateClick}>New portfolio</Button>
      </PortfolioTabs>
      {portfolios
        .filter(p => p.id === pId)
        .map(p => (
          <Portfolio key={p.id} portfolioId={p.id} />
        ))}
    </Wrapper>
  );
};
HomePage.displayName = 'HomePage';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PortfolioTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const PortfolioNavLink = styled(NavLink)<{$selected: boolean}>`
  padding: 8px 16px;
  border-radius: 4px;
  ${p => (p.$selected ? 'background-color: #ffffff11;' : false)}
`;
