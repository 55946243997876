import {createButton} from '@shared-web/components/core/button';
import {createTheme} from '@shared-web/theme/theme_base';
import {FrontendTheme} from '@shared-web/theme/theme_model';

export const theme: FrontendTheme = createTheme({
  input: {
    fontSize: 16,
    height: 46,
    backgroundColor: '#414249',
    backgroundColorDisabled: '#ffffff88',
    backgroundColorFocus: '#ffffff33',
    backgroundColorHover: '#ffffff33',
    borderColor: '#ffffff66',
    focusOutlineColor: '#80a6ff66',
    focusOutlineWidth: 4,
    focusBorderColor: '#be4c00',
    textColor: '#dddddd',
    textColorDisabled: '#00000077',
    focusTextColor: '#dddddd',
  },
  textarea: {
    fontSize: 16,
    backgroundColor: '#414249',
    backgroundColorDisabled: '#ffffff88',
    backgroundColorFocus: '#ffffff33',
    backgroundColorHover: '#ffffff33',
    borderColor: '#ffffff66',
    focusOutlineColor: '#80a6ff66',
    focusOutlineWidth: 4,
    focusBorderColor: '#be4c00',
    textColor: '#dddddd',
    textColorDisabled: '#00000077',
    focusTextColor: '#dddddd',
  },
  button: {
    paddingTop: 12,
    paddingBottom: 12,
    backgroundActive: '#be4c00',
    textColorActive: '#eeeeee',
    backgroundHover: '#c06223',
    textColorHover: '#f5f5f5',
    backgroundDisabled: '#be4c0077',
    textColorDisabled: '#cccccc',
    backgroundLoading: '#be4c0077',
    textColorLoading: '#cccccc',
    focusBorderColor: '#80a6ff66',
    focusBorderWidth: 4,
  },
  radio: {
    color: '#dddddd',
    fontSize: 18,
    labelHoverColor: '#ffffff11',
  },
  link: {
    textColorActive: '#eeeeee',
    textColorHover: '#f5f5f5',
    textColorDisabled: '#cccccc',
    textColorLoading: '#cccccc',
  },
});

export const RedButton = createButton({
  type: 'button',
  theme: 'button',
  themeOverrides: {
    backgroundActive: '#be0000',
    backgroundHover: '#c02323',
    backgroundDisabled: '#be000077',
    backgroundLoading: '#be000077',
  },
});
