import {styled} from 'styled-components';
import {Route} from 'wouter';

import {Modal} from '@shared-web/components/core/modal';

import {AllocationPage} from '@src/components/allocation_page';
import {CombinedPage} from '@src/components/combined_page';
import {HomePage} from '@src/components/home_page';
import {NavBar} from '@src/components/nav_bar';

export const App: React.FC = () => {
  const routes = (
    <>
      <Route path="/" component={HomePage} />
      <Route path="/portfolio/:portfolioId" component={HomePage} />
      <Route path="/combined" component={CombinedPage} />
      <Route path="/allocation" component={AllocationPage} />
    </>
  );

  return (
    <Route path="/" nest>
      <>
        <Wrapper>
          <NavBar />
          {routes}
        </Wrapper>
        <Modal />
      </>
    </Route>
  );
};
App.displayName = 'App';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #24252d;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
