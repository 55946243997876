import {
  Chart,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeSeriesScale,
  Tooltip,
} from 'chart.js'; // eslint-disable-line import/no-unresolved

import {TimeScale} from '@shared-web/components/core/chart/time_scale';

export function registerChartJs(): void {
  Chart.register(
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    TimeScale,
    TimeSeriesScale,
    Legend
  );
}
