import {createDataStore} from '@shared-web/lib/data_store';

const STORAGE_KEY = 'portfolios';

export interface PortfolioStock {
  symbol: string;
  quantity: number;
}

export interface Portfolio {
  id: string;
  label: string;
  stocks: PortfolioStock[];
}

const portfoliosStore = createDataStore<Portfolio[]>(loadPortfolios());

function loadPortfolios(): Portfolio[] {
  const raw = localStorage.getItem(STORAGE_KEY);
  if (raw === null) {
    const portfolio: Portfolio[] = [];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(portfolio));
    return portfolio;
  }
  return JSON.parse(raw);
}

export const getPortfolios = portfoliosStore.getData;
export const usePortfolios = portfoliosStore.useData;
export const updatePortfolios: typeof portfoliosStore.updateData = fn => {
  const updated = fn(portfoliosStore.getData());
  portfoliosStore.setData(updated);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(updated));
};
