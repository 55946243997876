import {createRoot} from 'react-dom/client';

import {addPrefix} from '@shared/lib/type_utils';

import {CssReset} from '@shared-web/components/core/css_reset';
import {GlobalStyle} from '@shared-web/components/core/global_styles';
import {ThemeContext} from '@shared-web/theme/theme_context';

import {App} from '@src/components/app';
import {registerChartJs} from '@src/lib/chartjs';
import {initStockData} from '@src/lib/stock_prices';
import {theme} from '@src/theme';

registerChartJs();
initStockData();

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <ThemeContext.Provider value={theme}>
      <CssReset />
      <App />
      <GlobalStyle {...addPrefix(theme.main, '$')} />
    </ThemeContext.Provider>
  );
}
