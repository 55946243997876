import {Arr, Num, Obj, OptArr, OptObj, SchemaToType, Str} from '@shared/api/core/api_schema';

const StockQuoteSchema = Obj({
  symbol: Str(),
  name: Str(),
  type: Str(),
  exchange: Str(),
  industry: Str(),
  sector: Str(),
});
export type StockQuote = SchemaToType<typeof StockQuoteSchema>;

const StockPricesSchema = Obj({
  realtime: OptObj({
    price: Num(),
    currency: Str(),
    change: Num(),
  }),
  historic: OptArr(
    Obj({
      price: Num(),
      ts: Num(),
    })
  ),
});
export type StockPrices = SchemaToType<typeof StockPricesSchema>;

export const APP_API = {
  '/stock-search': {
    POST: {
      req: Obj({query: Str()}),
      res: Obj({quotes: Arr(StockQuoteSchema)}),
    },
  },
  '/stock-prices': {
    POST: {
      req: Obj({symbol: Str()}),
      res: StockPricesSchema,
    },
  },
};
