import {StockPrices, StockQuote} from '@shared/api/app_api';

import {createMapStore} from '@shared-web/lib/map_data_store';

import {apiCall} from '@src/lib/api';
import {getPortfolios} from '@src/lib/portfolio';

export interface StockData {
  quote?: StockQuote;
  prices?: StockPrices;
}

const stockDataStore = createMapStore<string, StockData>();
export const useStockData = stockDataStore.useData;
export const setStockData = stockDataStore.setData;
export const getStockData = stockDataStore.getData;
export const getAllStockData = stockDataStore.getAllData;
export const useAllStockData = stockDataStore.useAllData;

export function initStockData(): void {
  const allStockData = getAllStockData();
  for (const portfolio of [...getPortfolios(), {stocks: [{symbol: 'SPY'}]}]) {
    for (const {symbol} of portfolio.stocks) {
      const stockData = allStockData.get(symbol);
      if (!stockData?.quote) {
        apiCall('POST /stock-search', {query: symbol})
          .then(({quotes}) => {
            const current = getStockData(symbol);
            setStockData(symbol, {...current, quote: quotes[0]});
          })
          .catch(() => {});
      }
      if (!stockData?.prices) {
        apiCall('POST /stock-prices', {symbol})
          .then(prices => {
            const current = getStockData(symbol);
            setStockData(symbol, {...current, prices});
          })
          .catch(() => {});
      }
    }
  }
}
