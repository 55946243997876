import {FC} from 'react';
import {styled} from 'styled-components';

import {PortfolioInfo} from '@src/components/portfolio_info';
import {usePortfolios} from '@src/lib/portfolio';

interface CombinedPageProps {}

export const CombinedPage: FC<CombinedPageProps> = () => {
  const portfolios = usePortfolios();

  return (
    <Wrapper>
      <AllPortfolioWrapper>
        <Title>ALL</Title>
        <PortfolioInfo portfolios={portfolios} />
      </AllPortfolioWrapper>
      <SubPortfolios>
        {portfolios.map(p => (
          <SubPortfolioWrapper key={p.id}>
            <Title>{p.label}</Title>
            <PortfolioInfo portfolios={[p]} />
          </SubPortfolioWrapper>
        ))}
      </SubPortfolios>
    </Wrapper>
  );
};

CombinedPage.displayName = 'CombinedPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 32px;
`;

const SubPortfolioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #ffffff05;
  width: calc(50% - 16px);
`;

const SubPortfolios = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px 16px;
`;

const AllPortfolioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #ffffff05;
`;

const Title = styled.div`
  font-size: 20px;
`;
