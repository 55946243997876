import {last} from '@shared/lib/array_utils';

import {
  dayHalves,
  dayHeights,
  dayQuarters,
  daysInWeek,
  firstDayOfTheWeek,
  hourQuarters,
  hourTwelves,
  minuteQuarters,
  minuteTwelves,
  secondTenths,
  yearHalves,
  yearQuarters,
} from '@shared-web/components/core/chart/time_axis/time_constants';

export interface PeriodAligner {
  current: (date: Date, utc: boolean) => Date;
  next: (date: Date, utc: boolean) => Date;
}

// Decade, century, millenia

export const MilleniaPeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc
      ? new Date(Date.UTC(1000 * Math.floor(date.getUTCFullYear() / 1000), 0))
      : new Date(1000 * Math.floor(date.getFullYear() / 1000), 0),
  next: (date, utc) =>
    utc
      ? new Date(Date.UTC(1000 * (1 + Math.floor(date.getUTCFullYear() / 1000)), 0))
      : new Date(1000 * (1 + Math.floor(date.getFullYear() / 1000)), 0),
};

export const CenturyPeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc
      ? new Date(Date.UTC(100 * Math.floor(date.getUTCFullYear() / 100), 0))
      : new Date(100 * Math.floor(date.getFullYear() / 100), 0),
  next: (date, utc) =>
    utc
      ? new Date(Date.UTC(100 * (1 + Math.floor(date.getUTCFullYear() / 100)), 0))
      : new Date(100 * (1 + Math.floor(date.getFullYear() / 100)), 0),
};

export const DecadePeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc
      ? new Date(Date.UTC(10 * Math.floor(date.getUTCFullYear() / 10), 0))
      : new Date(10 * Math.floor(date.getFullYear() / 10), 0),
  next: (date, utc) =>
    utc
      ? new Date(Date.UTC(10 * (1 + Math.floor(date.getUTCFullYear() / 10)), 0))
      : new Date(10 * (1 + Math.floor(date.getFullYear() / 10)), 0),
};

// Year & Year fractions

export const YearPeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc ? new Date(Date.UTC(date.getUTCFullYear(), 0)) : new Date(date.getFullYear(), 0),
  next: (date, utc) =>
    utc ? new Date(Date.UTC(date.getUTCFullYear() + 1, 0)) : new Date(date.getFullYear() + 1, 0),
};

function currentYearFraction(date: Date, yearFraction: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  for (const [index, current] of yearFraction.entries()) {
    const next = yearFraction[index + 1];
    if (next !== undefined && date.getMonth() >= next) {
      continue;
    }
    return utc ? new Date(Date.UTC(year, current)) : new Date(year, current);
  }
  const lastYearFraction = last(yearFraction);
  if (lastYearFraction === undefined) {
    throw new Error(`Failure to get current year fraction. Empty fraction array provided.`);
  }
  return utc ? new Date(Date.UTC(year, lastYearFraction)) : new Date(year, lastYearFraction);
}
function nextYearFraction(date: Date, yearFraction: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  for (let index = 1; index < yearFraction.length; index++) {
    const month = yearFraction[index];
    if (month !== undefined && date.getUTCMonth() < month) {
      return utc ? new Date(Date.UTC(year, month)) : new Date(year, month);
    }
  }
  const [firstYearFraction] = yearFraction;
  if (firstYearFraction === undefined) {
    throw new Error(`Failure to get next year fraction. Empty year fraction array provided.`);
  }
  return utc
    ? new Date(Date.UTC(year + 1, firstYearFraction))
    : new Date(year + 1, firstYearFraction);
}

export const HalfYearPeriodAligner: PeriodAligner = {
  current: (date, utc) => currentYearFraction(date, yearHalves, utc),
  next: (date, utc) => nextYearFraction(date, yearHalves, utc),
};

export const QuarterYearPeriodAligner: PeriodAligner = {
  current: (date, utc) => currentYearFraction(date, yearQuarters, utc),
  next: (date, utc) => nextYearFraction(date, yearQuarters, utc),
};

// Month

export const MonthPeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc
      ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()))
      : new Date(date.getFullYear(), date.getMonth()),
  next: (date, utc) =>
    utc
      ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1))
      : new Date(date.getFullYear(), date.getMonth() + 1),
};

// Week

export const WeekPeriodAligner: PeriodAligner = {
  current: (date, utc) => {
    const currentWeek = utc
      ? new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate() - ((date.getUTCDay() + daysInWeek - firstDayOfTheWeek) % daysInWeek)
          )
        )
      : new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - ((date.getDay() + daysInWeek - firstDayOfTheWeek) % daysInWeek)
        );
    return currentWeek;
  },
  next: (date, utc) => {
    const nextWeek = utc
      ? new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate() +
              daysInWeek -
              ((date.getUTCDay() + daysInWeek - firstDayOfTheWeek) % daysInWeek)
          )
        )
      : new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() +
            daysInWeek -
            ((date.getDay() + daysInWeek - firstDayOfTheWeek) % daysInWeek)
        );
    return nextWeek;
  },
};

// Day and Day fractions

export const DayPeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc
      ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
      : new Date(date.getFullYear(), date.getMonth(), date.getDate()),
  next: (date, utc) =>
    utc
      ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1))
      : new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1),
};

function currentDayFraction(date: Date, dayFractions: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const day = utc ? date.getUTCDate() : date.getDate();
  for (let index = 0; index < dayFractions.length - 1; index++) {
    const current = dayFractions[index];
    const next = dayFractions[index + 1];
    const hour = utc ? date.getUTCHours() : date.getHours();
    if (next !== undefined && hour >= next) {
      continue;
    }
    return utc
      ? new Date(Date.UTC(year, month, day, current))
      : new Date(year, month, day, current);
  }
  return utc
    ? new Date(Date.UTC(year, month, day, dayFractions.at(-1)))
    : new Date(year, month, day, dayFractions.at(-1));
}
function nextDayFraction(date: Date, dayFractions: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const day = utc ? date.getUTCDate() : date.getDate();
  for (let index = 1; index < dayFractions.length; index++) {
    const current = dayFractions[index];
    const hour = utc ? date.getUTCHours() : date.getHours();
    if (current !== undefined && hour < current) {
      return utc
        ? new Date(Date.UTC(year, month, day, current))
        : new Date(year, month, day, current);
    }
  }
  return utc
    ? new Date(Date.UTC(year, month, day + 1, dayFractions[0]))
    : new Date(year, month, day + 1, dayFractions[0]);
}

export const HalfDayPeriodAligner: PeriodAligner = {
  current: (date, utc) => currentDayFraction(date, dayHalves, utc),
  next: (date, utc) => nextDayFraction(date, dayHalves, utc),
};

export const QuarterDayPeriodAligner: PeriodAligner = {
  current: (date, utc) => currentDayFraction(date, dayQuarters, utc),
  next: (date, utc) => nextDayFraction(date, dayQuarters, utc),
};

export const HeighthDayPeriodAligner: PeriodAligner = {
  current: (date, utc) => currentDayFraction(date, dayHeights, utc),
  next: (date, utc) => nextDayFraction(date, dayHeights, utc),
};

// Hour & Hour fractions

export const HourPeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc
      ? new Date(
          Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours())
        )
      : new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()),
  next: (date, utc) =>
    utc
      ? new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours() + 1
          )
        )
      : new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + 1),
};

function currentHourFraction(date: Date, hourFractions: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const day = utc ? date.getUTCDate() : date.getDate();
  const hour = utc ? date.getUTCHours() : date.getHours();
  for (let index = 0; index < hourFractions.length - 1; index++) {
    const current = hourFractions[index];
    const next = hourFractions[index + 1];
    const minute = utc ? date.getUTCMinutes() : date.getMinutes();
    if (next !== undefined && minute >= next) {
      continue;
    }
    return utc
      ? new Date(Date.UTC(year, month, day, hour, current))
      : new Date(year, month, day, hour, current);
  }
  return utc
    ? new Date(Date.UTC(year, month, day, hour, hourFractions.at(-1)))
    : new Date(year, month, day, hour, hourFractions.at(-1));
}
function nextHourFraction(date: Date, hourFractions: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const day = utc ? date.getUTCDate() : date.getDate();
  const hour = utc ? date.getUTCHours() : date.getHours();
  for (let index = 1; index < hourFractions.length; index++) {
    const current = hourFractions[index];
    const minute = utc ? date.getUTCMinutes() : date.getMinutes();
    if (current !== undefined && minute < current) {
      return utc
        ? new Date(Date.UTC(year, month, day, hour, current))
        : new Date(year, month, day, hour, current);
    }
  }
  return utc
    ? new Date(Date.UTC(year, month, day, hour + 1, hourFractions[0]))
    : new Date(year, month, day, hour + 1, hourFractions[0]);
}

export const QuarterHourPeriodAligner: PeriodAligner = {
  current: (date, utc) => currentHourFraction(date, hourQuarters, utc),
  next: (date, utc) => nextHourFraction(date, hourQuarters, utc),
};

export const TwelfthHourPeriodAligner: PeriodAligner = {
  current: (date, utc) => currentHourFraction(date, hourTwelves, utc),
  next: (date, utc) => nextHourFraction(date, hourTwelves, utc),
};

// Minute & Minute fractions

export const MinutePeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc
      ? new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes()
          )
        )
      : new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes()
        ),
  next: (date, utc) =>
    utc
      ? new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes() + 1
          )
        )
      : new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes() + 1
        ),
};

function currentMinuteFraction(date: Date, minuteFractions: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const day = utc ? date.getUTCDate() : date.getDate();
  const hour = utc ? date.getUTCHours() : date.getHours();
  const minute = utc ? date.getUTCMinutes() : date.getMinutes();
  for (let index = 0; index < minuteFractions.length - 1; index++) {
    const current = minuteFractions[index];
    const next = minuteFractions[index + 1];
    const second = utc ? date.getUTCSeconds() : date.getSeconds();
    if (next !== undefined && second >= next) {
      continue;
    }
    return utc
      ? new Date(Date.UTC(year, month, day, hour, minute, current))
      : new Date(year, month, day, hour, minute, current);
  }
  return utc
    ? new Date(Date.UTC(year, month, day, hour, minute, minuteFractions.at(-1)))
    : new Date(year, month, day, hour, minute, minuteFractions.at(-1));
}
function nextMinuteFraction(date: Date, minuteFractions: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const day = utc ? date.getUTCDate() : date.getDate();
  const hour = utc ? date.getUTCHours() : date.getHours();
  const minute = utc ? date.getUTCMinutes() : date.getMinutes();
  for (let index = 1; index < minuteFractions.length; index++) {
    const current = minuteFractions[index];
    const second = utc ? date.getUTCSeconds() : date.getSeconds();
    if (current !== undefined && second < current) {
      return utc
        ? new Date(Date.UTC(year, month, day, hour, minute, current))
        : new Date(year, month, day, hour, minute, current);
    }
  }
  return utc
    ? new Date(Date.UTC(year, month, day, hour, minute + 1, minuteFractions[0]))
    : new Date(year, month, day, hour, minute + 1, minuteFractions[0]);
}

export const QuarterMinutePeriodAligner: PeriodAligner = {
  current: (date, utc) => currentMinuteFraction(date, minuteQuarters, utc),
  next: (date, utc) => nextMinuteFraction(date, minuteQuarters, utc),
};

export const TwelfthMinutePeriodAligner: PeriodAligner = {
  current: (date, utc) => currentMinuteFraction(date, minuteTwelves, utc),
  next: (date, utc) => nextMinuteFraction(date, minuteTwelves, utc),
};

// Second & Second fractions

export const SecondPeriodAligner: PeriodAligner = {
  current: (date, utc) =>
    utc
      ? new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
          )
        )
      : new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds()
        ),
  next: (date, utc) =>
    utc
      ? new Date(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds() + 1
          )
        )
      : new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds() + 1
        ),
};

function currentSecondFraction(date: Date, secondFractions: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const day = utc ? date.getUTCDate() : date.getDate();
  const hour = utc ? date.getUTCHours() : date.getHours();
  const minute = utc ? date.getUTCMinutes() : date.getMinutes();
  const second = utc ? date.getUTCSeconds() : date.getSeconds();
  for (let index = 0; index < secondFractions.length - 1; index++) {
    const current = secondFractions[index];
    const next = secondFractions[index + 1];
    const millisecond = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    if (next !== undefined && millisecond >= next) {
      continue;
    }
    return utc
      ? new Date(Date.UTC(year, month, day, hour, minute, second, current))
      : new Date(year, month, day, hour, minute, second, current);
  }
  return utc
    ? new Date(Date.UTC(year, month, day, hour, minute, second, secondFractions.at(-1)))
    : new Date(year, month, day, hour, minute, second, secondFractions.at(-1));
}
function nextSecondFraction(date: Date, secondFractions: number[], utc: boolean): Date {
  const year = utc ? date.getUTCFullYear() : date.getFullYear();
  const month = utc ? date.getUTCMonth() : date.getMonth();
  const day = utc ? date.getUTCDate() : date.getDate();
  const hour = utc ? date.getUTCHours() : date.getHours();
  const minute = utc ? date.getUTCMinutes() : date.getMinutes();
  const second = utc ? date.getUTCSeconds() : date.getSeconds();
  for (let index = 1; index < secondFractions.length; index++) {
    const current = secondFractions[index];
    const millisecond = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    if (current !== undefined && millisecond < current) {
      return utc
        ? new Date(Date.UTC(year, month, day, hour, minute, second, current))
        : new Date(year, month, day, hour, minute, second, current);
    }
  }
  return utc
    ? new Date(Date.UTC(year, month, day, hour, minute, second + 1, secondFractions[0]))
    : new Date(year, month, day, hour, minute, second + 1, secondFractions[0]);
}

export const TenthSecondPeriodAligner: PeriodAligner = {
  current: (date, utc) => currentSecondFraction(date, secondTenths, utc),
  next: (date, utc) => nextSecondFraction(date, secondTenths, utc),
};

export const HundredthSecondPeriodAligner: PeriodAligner = {
  current: (date, utc) => {
    const next = new Date(date.getTime());
    if (utc) {
      next.setUTCMilliseconds(next.getUTCMilliseconds() - (next.getUTCMilliseconds() % 10));
    } else {
      next.setMilliseconds(next.getMilliseconds() - (next.getMilliseconds() % 10));
    }
    return next;
  },
  next: (date, utc) => {
    const next = new Date(date.getTime());
    if (utc) {
      next.setUTCMilliseconds(next.getUTCMilliseconds() - (next.getUTCMilliseconds() % 10) + 10);
    } else {
      next.setMilliseconds(next.getMilliseconds() - (next.getMilliseconds() % 10) + 10);
    }
    return next;
  },
};

export const MillisecondPeriodAligner: PeriodAligner = {
  current: date => date,
  next: (date, utc) => {
    const next = new Date(date.getTime());
    if (utc) {
      next.setUTCMilliseconds(next.getUTCMilliseconds() + 1);
    } else {
      next.setMilliseconds(next.getMilliseconds() + 1);
    }
    return next;
  },
};
