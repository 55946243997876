import {FC, useMemo} from 'react';
import {styled} from 'styled-components';

import {sum} from '@shared/lib/array_utils';
import {startOfUtcWeek} from '@shared/lib/date_utils';

import {PriceChart} from '@src/components/price_chart';
import {Portfolio} from '@src/lib/portfolio';
import {useAllStockData} from '@src/lib/stock_prices';

interface PortfolioInfoProps {
  portfolios: Portfolio[];
}

function normalizeTs(ts: number): number {
  return startOfUtcWeek(new Date(ts)).getTime();
}

export const PortfolioInfo: FC<PortfolioInfoProps> = props => {
  const {portfolios} = props;
  const allPrices = useAllStockData();

  const portfolioPrices = useMemo(() => {
    const prices = new Map<number, Record<string, number>>();
    const stocks = new Set<string>();
    for (const portfolio of portfolios) {
      const pSize = sum(portfolio.stocks.map(s => s.quantity));
      for (const s of portfolio.stocks) {
        stocks.add(s.symbol);
        const priceData = allPrices.get(s.symbol);
        if (!priceData) {
          return undefined;
        }
        for (const p of priceData.prices?.historic ?? []) {
          const ts = normalizeTs(p.ts);
          let current = prices.get(ts);
          if (!current) {
            current = {};
            prices.set(ts, current);
          }
          current[s.symbol] = (p.price * s.quantity) / pSize;
        }
      }
    }
    return [...prices.entries()]
      .filter(e => Object.keys(e[1]).length === stocks.size)
      .sort((e1, e2) => e1[0] - e2[0]);
  }, [portfolios, allPrices]);

  if (!portfolioPrices) {
    return <Wrapper>Loading...</Wrapper>;
  }

  if (portfolioPrices.length === 0) {
    return <Wrapper>No data</Wrapper>;
  }

  return (
    <Wrapper>
      <PriceChart
        label="Portfolio"
        prices={portfolioPrices.map(([ts, p]) => ({
          ts,
          price: sum(Object.values(p)),
        }))}
      />
    </Wrapper>
  );
};

PortfolioInfo.displayName = 'PortfolioInfo';

const Wrapper = styled.div`
  display: flex;
`;
