import {CartesianScaleOptions, ChartArea, Scale, Tick} from 'chart.js'; // eslint-disable-line import/no-unresolved

import {LinearTimeScale} from '@shared-web/components/core/chart/scale';
import {TimeAxis} from '@shared-web/components/core/chart/time_axis/time_axis';
import {TimeAxisOptions} from '@shared-web/components/core/chart/time_axis/time_axis_options';
import {AllTimePeriodGroups} from '@shared-web/components/core/chart/time_axis/time_period_group';

declare module 'chart.js' {
  interface CartesianScaleTypeRegistry {
    timescale: {
      options: TimeScaleOptions & CartesianScaleOptions;
    };
  }
}

export interface TimeScaleOptions {
  options?: Partial<TimeAxisOptions>;
  dateFormat?: Intl.DateTimeFormatOptions;
  // In some cases we don't want the min/max to be at the edge of the axis.
  // For instance in the case of a bar chart, we would have the bars at the beginning/end of
  // the chart to be half off-screen since the center of the bar would be positionned at the edge
  // of the axis.
  // This value allow to remove/add milliseconds to the min/max.
  extraMsForPadding?: number;
}

export class TimeScale extends Scale<TimeScaleOptions & CartesianScaleOptions> {
  public static readonly id = 'timescale';
  private customAxis: TimeAxis | undefined;
  private dateFormat: Intl.DateTimeFormat | undefined;

  private getAxis(): TimeAxis {
    if (this.customAxis) {
      return this.customAxis;
    }
    return this.rebuildAxis();
  }

  private getDateFormat(): Intl.DateTimeFormat {
    if (!this.dateFormat) {
      this.dateFormat = new Intl.DateTimeFormat(undefined, this.options.dateFormat);
    }
    return this.dateFormat;
  }

  private rebuildAxis(): TimeAxis {
    const newAxis = new TimeAxis({
      scale: new LinearTimeScale(),
      timePeriodGroups: AllTimePeriodGroups,
      options: this.options.options ?? {},
    });
    this.customAxis = newAxis;
    this.setDimensions();
    return newAxis;
  }

  public override init(options: TimeScaleOptions & CartesianScaleOptions): void {
    super.init(options);
    this.dateFormat = new Intl.DateTimeFormat(undefined, this.options.dateFormat);
  }

  public override determineDataLimits(): void {
    const {extraMsForPadding = 0} = this.options;
    let min = Number.POSITIVE_INFINITY;
    let max = Number.NEGATIVE_INFINITY;
    if (!this.chart.data.labels) {
      throw new Error('Expected "Chart.data.labels" to be defined');
    }
    for (const label of this.chart.data.labels) {
      if (typeof label !== 'number') {
        throw new Error(`Expected number in "Chart.data.labels", got ${typeof label}`);
      }
      if (label < min) {
        min = label;
      }
      if (label > max) {
        max = label;
      }
    }
    this.min = min - extraMsForPadding;
    this.max = max + extraMsForPadding;
    this.rebuildAxis();
  }

  public override buildTicks(): Tick[] {
    return []; // Not used
  }

  public override getLabelForValue(value: number): string {
    return this.getDateFormat().format(new Date(value));
  }

  public override getPixelForTick(): number {
    return 0; // Not used
  }

  public override getPixelForValue(value: number): number {
    return this.left + (this.width * (value - this.min)) / (this.max - this.min);
  }

  public override getValueForPixel(pixel: number): number | undefined {
    const ts = ((pixel - this.left) / this.width) * ((this.max - this.min) / (pixel - this.min));
    let bestTs: number | undefined;
    let bestDistance = Number.POSITIVE_INFINITY;
    for (const label of this.chart.data.labels ?? []) {
      if (typeof label === 'number') {
        const distance = Math.abs(ts - label);
        if (distance < bestDistance) {
          bestDistance = distance;
          bestTs = ts;
        }
      }
    }
    return bestTs;
  }

  public override draw(chartArea: ChartArea): void {
    const axis = this.getAxis();
    axis.drawTimeAxis(
      this.ctx,
      {
        x: chartArea.left,
        y: chartArea.bottom,
        width: chartArea.width,
        height: this.height,
      },
      new Date(this.min),
      new Date(this.max),
      true,
      true
    );
  }

  public override fit(): void {
    this.width = this.ctx.canvas.width;
    this.height = this.getAxis().getHeight();
  }
}
