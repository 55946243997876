import {last} from '@shared/lib/array_utils';
import {DeepPartial, removeUndefined} from '@shared/lib/type_utils';

import {Scale} from '@shared-web/components/core/chart/scale';
import {TextSizeCache} from '@shared-web/components/core/chart/text_size_cache';
import {
  defaultOptions,
  LineOptions,
  mergeOptions,
  TextOptions,
  TimeAxisOptions,
  TimePeriodOptions,
} from '@shared-web/components/core/chart/time_axis/time_axis_options';
import {TimePeriod} from '@shared-web/components/core/chart/time_axis/time_period';
import {TimePeriodGroup} from '@shared-web/components/core/chart/time_axis/time_period_group';
import {
  getTextZones,
  MeasuredText,
  TimePeriodTextZone,
} from '@shared-web/components/core/chart/time_axis/time_period_text_zone';

export interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

type TimePeriodTextZoneWithBestLabel = TimePeriodTextZone & {bestLabel: MeasuredText};

interface MeasuredTimePeriod {
  timePeriod: TimePeriod;
  allTextZones: TimePeriodTextZoneWithBestLabel[];
}

interface MeasuredGroup {
  group: TimePeriodGroup;
  measures: {
    subticks?: MeasuredTimePeriod;
    ticks: MeasuredTimePeriod;
    group: MeasuredTimePeriod;
  };
}

export interface TimeAxisConfig {
  scale: Scale<Date>;
  timePeriodGroups: TimePeriodGroup[];
  options?: DeepPartial<TimeAxisOptions>;
}

export class TimeAxis {
  private readonly textSizeCache: TextSizeCache;
  private readonly options: TimeAxisOptions;

  public constructor(private readonly config: TimeAxisConfig) {
    this.textSizeCache = new TextSizeCache();
    this.options = mergeOptions(defaultOptions, config.options ?? {});
  }

  private computeBestLabels(textZones: TimePeriodTextZone[]): TimePeriodTextZoneWithBestLabel[] {
    // Go through all text zones labels to catalog what indexes are not available
    const invalidIndexes = new Set<number>();
    let largestValidIndex = -1;
    for (const tz of textZones) {
      for (const [index, label] of tz.labels.entries()) {
        if (label === undefined) {
          if (tz.leftCut === 0 && tz.rightCut === 0) {
            invalidIndexes.add(index);
          }
        } else if (index > largestValidIndex) {
          largestValidIndex = index;
        }
      }
    }

    let smallestValidIndex: number | undefined;
    for (let i = 0; i <= largestValidIndex; i++) {
      if (!invalidIndexes.has(i)) {
        smallestValidIndex = i;
        break;
      }
    }

    if (smallestValidIndex === undefined) {
      throw new Error(`No valid label index available`);
    }
    const definedSmallestValidIndex = smallestValidIndex;

    return textZones.map(tz => ({
      ...tz,
      bestLabel: tz.labels[definedSmallestValidIndex] ?? {text: '', textWidth: 0, textHeight: 0},
    }));
  }

  private getMeasuredTimePeriod(
    timePeriod: TimePeriod,
    min: Date,
    max: Date,
    width: number,
    text: TextOptions,
    utc: boolean
  ): MeasuredTimePeriod | undefined {
    try {
      const allTextZones = getTextZones(
        this.config.scale,
        this.textSizeCache,
        min,
        max,
        width,
        timePeriod,
        text.font,
        text.padding,
        utc
      );

      return {
        timePeriod,
        allTextZones: this.computeBestLabels(allTextZones),
      };
    } catch {
      return undefined;
    }
  }

  private getBestMeasuredTicks(
    ticks: {main: TimePeriod; sub?: TimePeriod}[],
    min: Date,
    max: Date,
    width: number,
    text: TextOptions,
    utc: boolean
  ): {
    ticksMeasures: MeasuredTimePeriod | undefined;
    subticksMeasures: MeasuredTimePeriod | undefined;
  } {
    for (const {main, sub} of ticks) {
      try {
        const mainTextZones = getTextZones(
          this.config.scale,
          this.textSizeCache,
          min,
          max,
          width,
          main,
          text.font,
          text.padding,
          utc
        );
        let subTextZones: TimePeriodTextZone[] | undefined;
        try {
          if (sub !== undefined) {
            subTextZones = getTextZones(
              this.config.scale,
              this.textSizeCache,
              min,
              max,
              width,
              sub,
              text.font,
              text.padding,
              utc
            );
          }
        } catch {
          // Sub ticks are allowed to fail to render
        }
        return {
          ticksMeasures: {timePeriod: main, allTextZones: this.computeBestLabels(mainTextZones)},
          subticksMeasures:
            sub && subTextZones
              ? {timePeriod: sub, allTextZones: this.computeBestLabels(subTextZones)}
              : undefined,
        };
      } catch {
        continue;
      }
    }
    return {ticksMeasures: undefined, subticksMeasures: undefined};
  }

  private measureGroups(rect: Rectangle, min: Date, max: Date, utc: boolean): MeasuredGroup[] {
    const groupsAndMeasures: MeasuredGroup[] = removeUndefined(
      this.config.timePeriodGroups.map(timePeriodGroup => {
        const {ticks, group} = timePeriodGroup;

        const {ticksMeasures, subticksMeasures} = this.getBestMeasuredTicks(
          ticks,
          min,
          max,
          rect.width,
          this.options.ticks.text,
          utc
        );
        if (ticksMeasures === undefined) {
          return undefined;
        }

        const groupMeasures = this.getMeasuredTimePeriod(
          group,
          min,
          max,
          rect.width,
          this.options.group.text,
          utc
        );
        if (!groupMeasures) {
          return undefined;
        }

        const measuredGroup: MeasuredGroup = {
          group: timePeriodGroup,
          measures: {
            subticks: subticksMeasures,
            ticks: ticksMeasures,
            group: groupMeasures,
          },
        };
        return measuredGroup;
      })
    );

    return groupsAndMeasures;
  }

  private applyLineOptions(ctx: CanvasRenderingContext2D, lineOptions: LineOptions): void {
    ctx.strokeStyle = lineOptions.strokeStyle;

    ctx.lineCap = lineOptions.lineCap;
    ctx.lineDashOffset = lineOptions.lineDashOffset;
    ctx.lineJoin = lineOptions.lineJoin;
    ctx.lineWidth = lineOptions.lineWidth;
    ctx.miterLimit = lineOptions.miterLimit;

    ctx.shadowBlur = lineOptions.shadowBlur;
    ctx.shadowColor = lineOptions.shadowColor;
    ctx.shadowOffsetX = lineOptions.shadowOffsetX;
    ctx.shadowOffsetY = lineOptions.shadowOffsetY;
  }

  private applyTextOptions(ctx: CanvasRenderingContext2D, textOptions: TextOptions): void {
    ctx.font = textOptions.font;
    ctx.fillStyle = textOptions.fillStyle;
  }

  private renderTextZones(
    ctx: CanvasRenderingContext2D,
    measuredTimePeriod: MeasuredTimePeriod | undefined,
    options: TimePeriodOptions,
    renderer: (
      ctx: CanvasRenderingContext2D,
      textZone: TimePeriodTextZoneWithBestLabel,
      height: number,
      shouldStroke: boolean
    ) => void,
    noTranslate?: boolean
  ): void {
    ctx.save();
    this.applyTextOptions(ctx, options.text);
    this.applyLineOptions(ctx, options.line);
    if (measuredTimePeriod?.allTextZones) {
      for (const textZone of measuredTimePeriod.allTextZones) {
        renderer(
          ctx,
          textZone,
          options.height,
          options.text.strokeStyle !== undefined || options.text.strokeWidth !== undefined
        );
      }
    }
    ctx.restore();
    if (!noTranslate) {
      ctx.translate(0, options.height);
    }
  }

  private renderTextZoneRectangle(
    ctx: CanvasRenderingContext2D,
    textZone: TimePeriodTextZone,
    height: number
  ): void {
    const zoneWidth = textZone.end - textZone.start;

    type Corner = [number, number];
    const topLeft: Corner = [textZone.start, 0];
    const topRight: Corner = [textZone.start + zoneWidth, 0];
    const bottomRight: Corner = [textZone.start + zoneWidth, height];
    const bottomLeft: Corner = [textZone.start, height];

    ctx.beginPath();
    ctx.moveTo(...topLeft);
    ctx.lineTo(...topRight);
    if (textZone.rightCut > 0) {
      ctx.moveTo(...bottomRight);
    } else {
      ctx.lineTo(...bottomRight);
    }
    ctx.lineTo(...bottomLeft);
    if (textZone.leftCut > 0) {
      ctx.moveTo(...topLeft);
    } else {
      ctx.lineTo(...topLeft);
    }
    ctx.stroke();
  }

  public drawTimeAxis(
    ctx: CanvasRenderingContext2D,
    rect: Rectangle,
    min: Date,
    max: Date,
    drawTickLines: boolean | undefined,
    drawTickGroups: boolean | undefined
  ): void {
    this.textSizeCache.setContext(ctx);
    const groups = this.measureGroups(rect, min, max, this.options.utc);
    const bestGroup = last(groups);
    if (bestGroup === undefined) {
      throw new Error(
        `No time group available to draw time axis for rect ${JSON.stringify(
          rect
        )} with min=${min} and max=${max}`
      );
    }
    const {subticks, ticks, group} = bestGroup.measures;

    ctx.save();
    ctx.translate(rect.x, rect.y);

    // Gridline
    ctx.save();
    this.applyLineOptions(ctx, this.options.ticks.gridLine);
    if (drawTickLines !== false) {
      for (const tick of ticks.allTextZones) {
        ctx.beginPath();
        ctx.moveTo(tick.start, 0);
        ctx.lineTo(tick.start, rect.height - this.getHeight());
        ctx.stroke();
      }

      // Draw right axis
      ctx.beginPath();
      ctx.moveTo(rect.width, 0);
      ctx.lineTo(rect.width, rect.height - this.getHeight());
      ctx.stroke();
    }

    ctx.restore();
    ctx.translate(0, rect.height - this.getHeight() - this.options.ticks.height);

    // Subticks
    this.renderTextZones(
      ctx,
      subticks,
      this.options.ticks,
      (c, textZone, height) => {
        c.beginPath();
        c.moveTo(textZone.start, height - this.options.subticksHeight);
        c.lineTo(textZone.start, height);
        c.stroke();
      },
      true
    );

    // Ticks
    this.renderTextZones(ctx, ticks, this.options.ticks, (c, textZone, height) => {
      const label = textZone.bestLabel;
      const {padding} = this.options.ticks.text;

      if (textZone.start > 0) {
        // Tick line
        c.beginPath();
        c.moveTo(textZone.start, height - this.options.ticksHeight);
        c.lineTo(textZone.start, height);
        c.stroke();
      }

      // Tick text
      const textY =
        drawTickGroups !== false
          ? height - padding - (height - label.textHeight) / 2
          : height + 2 * padding + label.textHeight;
      const textX = textZone.start - label.textWidth / 2;

      const {strokeStyle, strokeWidth} = this.options.ticks.text;
      const shouldStroke = strokeStyle !== undefined || strokeWidth !== undefined;
      ctx.miterLimit = 2;
      ctx.lineJoin = 'round';
      if (strokeStyle !== undefined) {
        ctx.strokeStyle = strokeStyle;
      }
      if (strokeWidth !== undefined) {
        ctx.lineWidth = strokeWidth;
      }
      const drawText = (text: string, x: number, y: number): void => {
        if (shouldStroke) {
          c.strokeText(text, x, y);
        }
        c.fillText(text, x, y);
      };

      if (textX < 0) {
        if (textZone.start > 0 && label.textWidth + padding < textZone.end) {
          drawText(label.text, 0, textY);
        }
      } else if (textX + label.textWidth > textZone.end) {
        drawText(label.text, textZone.end - label.textWidth, textY);
      } else {
        drawText(label.text, textX, textY);
      }
    });

    // Group text
    if (drawTickGroups !== false) {
      this.renderTextZones(ctx, group, this.options.group, (c, textZone, height) => {
        const {end, start, bestLabel} = textZone;
        const {text, textHeight, textWidth} = bestLabel;

        const x = start + (end - start - textWidth) / 2;
        if (end - start >= textWidth + this.options.group.text.padding) {
          c.fillText(text, x, height - (height - textHeight) / 2);
        }

        this.renderTextZoneRectangle(c, textZone, height - this.options.group.line.lineWidth / 2);
      });
    }

    ctx.restore();
  }

  public getHeight(): number {
    return this.options.group.height;
  }
}
