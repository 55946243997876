import {useCallback, useEffect, useRef, useState} from 'react';
import {styled} from 'styled-components';

import {StockQuote} from '@shared/api/app_api';

import {Autocomplete} from '@shared-web/components/core/autocomplete';
import {Button} from '@shared-web/components/core/button';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {chartIcon} from '@shared-web/components/icons/chart_icon';
import {notifyError} from '@shared-web/lib/notification';
import {Custom} from '@shared-web/lib/react';

import {PriceChart} from '@src/components/price_chart';
import {apiCall} from '@src/lib/api';
import {getStockData, setStockData, useStockData} from '@src/lib/stock_prices';

interface StockLineProps {
  symbol?: string;
  onStockChange: (newSymbol: string, el: HTMLDivElement) => void;
}

export const StockLine: Custom<StockLineProps, 'div'> = props => {
  const {symbol, onStockChange: onChange, ...rest} = props;
  const stockData = useStockData(symbol ?? '');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onQuoteChange = useCallback(
    (quote: StockQuote | undefined) => {
      if (quote !== undefined) {
        const current = getStockData(quote.symbol);
        setStockData(quote.symbol, {...current, quote});
      }
      if (!quote || !wrapperRef.current) {
        return;
      }
      onChange(quote.symbol, wrapperRef.current);
    },
    [onChange]
  );

  useEffect(() => {
    if (symbol === undefined || stockData?.quote !== undefined) {
      return;
    }
    apiCall('POST /stock-search', {query: symbol})
      .then(({quotes}) => {
        const current = getStockData(symbol);
        setStockData(symbol, {...current, quote: quotes[0]});
      })
      .catch(notifyError);
  }, [stockData?.quote, symbol]);

  const quoteToString = useCallback((quote: StockQuote) => `${quote.symbol} · ${quote.name}`, []);
  const quoteToKey = useCallback((quote: StockQuote) => quote.symbol, []);
  const lookupQuote = useCallback(
    async (input: string) =>
      apiCall('POST /stock-search', {query: input}).then(({quotes}) => quotes),
    []
  );
  const quoteElement = useCallback((quote: StockQuote, highlighted: boolean) => {
    return (
      <StockQuoteWrapper $highlighted={highlighted}>
        <StockQuoteWrapperTop>
          <StockQuoteSymbol>{quote.symbol}</StockQuoteSymbol>
          <StockQuoteName>{quote.name}</StockQuoteName>
        </StockQuoteWrapperTop>
        <StockQuoteWrapperBottom>
          <div>{quote.exchange}</div>
          <div>{quote.industry}</div>
          <div>{quote.sector}</div>
          <div>{quote.type}</div>
        </StockQuoteWrapperBottom>
      </StockQuoteWrapper>
    );
  }, []);

  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  useEffect(() => {
    if (symbol === undefined || stockData?.prices !== undefined) {
      return;
    }
    setIsLoadingPrices(true);
    apiCall('POST /stock-prices', {symbol})
      .then(prices => {
        const current = getStockData(symbol);
        setStockData(symbol, {...current, prices});
        setIsLoadingPrices(false);
      })
      .catch((err: unknown) => {
        notifyError(err);
        setIsLoadingPrices(false);
      });
  }, [stockData?.prices, symbol]);

  const [chartShown, setChartShown] = useState(false);
  const handleToggleChartClick = useCallback(() => {
    setChartShown(curr => !curr);
  }, []);

  return (
    <Wrapper>
      <Form ref={wrapperRef} {...rest}>
        <div>
          <Autocomplete<StockQuote>
            item={stockData?.quote}
            itemToInputString={quoteToString}
            syncState={onQuoteChange}
            lookupItem={lookupQuote}
            itemElement={quoteElement}
            itemToKey={quoteToKey}
            label={'Stock'}
          />
        </div>
        <Button onClick={handleToggleChartClick}>
          <SvgIcon icon={chartIcon} size={22} color={'#ccc'} />
        </Button>
        <Price>
          {stockData?.prices?.realtime ? (
            <>
              <StockPrice>{`${stockData.prices.realtime.price} ${stockData.prices.realtime.currency}`}</StockPrice>
              <StockChange
                $negative={stockData.prices.realtime.change < 0}
                $positive={stockData.prices.realtime.change > 0}
              >
                {stockData.prices.realtime.change.toLocaleString(undefined, {
                  style: 'percent',
                  maximumFractionDigits: 2,
                })}
              </StockChange>
            </>
          ) : isLoadingPrices || (!stockData?.quote && symbol !== undefined) ? (
            <>
              <StockLoading>loading prices...</StockLoading>
            </>
          ) : (
            <></>
          )}
        </Price>
      </Form>
      {stockData?.prices?.historic && chartShown ? (
        <PriceChart prices={stockData.prices.historic} label={stockData.quote?.name ?? ''} />
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

StockLine.displayName = 'StockLine';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Form = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 32px;
  & > div:first-of-type {
    flex-grow: 1;
  }
`;

const Price = styled.div`
  display: flex;
  gap: 8px;
  width: 200px;
  flex-shrink: 0;
  line-height: 46px;
`;

const StockQuoteWrapper = styled.div<{$highlighted?: boolean}>`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: ${p => (p.$highlighted ? '#414249' : '#60616c')};
`;

const StockQuoteWrapperTop = styled.div`
  display: flex;
  gap: 8px;
`;

const StockQuoteWrapperBottom = styled.div`
  display: flex;
  gap: 8px;
`;

const StockQuoteSymbol = styled.div`
  color: #aaa;
`;
const StockQuoteName = styled.div`
  color: #ddd;
`;

const StockPrice = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const StockChange = styled.div<{$negative?: boolean; $positive?: boolean}>`
  font-size: 20px;
  color: ${p => (p.$positive ? '#00ff00' : p.$negative ? '#ff0000' : '#ffffff')};
`;

const StockLoading = styled.div`
  font-style: italic;
  color: #ccc;
`;
