import {FC, useMemo} from 'react';
import {styled} from 'styled-components';

interface PerfProps {
  perf?: number;
}

const PerfColors = [
  'rgb(0,176,80)',
  'rgb(46,183,84)',
  'rgb(92,191,88)',
  'rgb(138,199,92)',
  'rgb(184,207,96)',
  'rgb(230,215,100)',
  'rgb(248,199,93)',
  'rgb(237,159,74)',
  'rgb(225,119,56)',
  'rgb(214,79,37)',
  'rgb(214,79,37)',
  'rgb(214,79,37)',
];

export const Perf: FC<PerfProps> = ({perf}) => {
  const percent =
    perf?.toLocaleString(undefined, {style: 'percent', minimumFractionDigits: 1}) ?? '';
  const color =
    useMemo(() => {
      if (perf === undefined) {
        return undefined;
      }
      const amplitude = 0.05;
      const colorCount = PerfColors.length;
      if (perf > amplitude) {
        return PerfColors[0];
      } else if (perf < -amplitude) {
        return PerfColors[colorCount - 1];
      }
      const index = Math.round(colorCount * (1 - (perf + amplitude) / (2 * amplitude)));
      const normalizedIndex = Math.min(Math.max(0, index), colorCount - 1);
      return PerfColors[normalizedIndex];
    }, [perf]) ?? '#fff';
  return <Wrapper $color={color}>{percent}</Wrapper>;
};

Perf.displayName = 'Perf';

const Wrapper = styled.div<{$color: string}>`
  display: flex;
  color: ${p => p.$color};
`;
