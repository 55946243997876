interface Price {
  price: number;
  ts: number;
}

interface Month {
  month: number;
  year: number;
}

interface MonthlyPerf {
  month: number;
  perf: number;
}

interface YearlyPerf {
  year: number;
  perf: number;
  monthly: MonthlyPerf[];
}

interface GlobalPerf {
  perf: number;
  yearly: YearlyPerf[];
}

function getMonth(ts: number): Month {
  const d = new Date(ts);
  return {
    year: d.getFullYear(),
    month: d.getMonth(),
  };
}

function sameMonth(month1: Month, month2: Month): boolean {
  return month1.month === month2.month && month1.year === month2.year;
}

export function perfs(prices: Price[]): GlobalPerf {
  const firstPrice = prices[0];
  const lastPrice = prices.at(-1);
  if (!firstPrice || !lastPrice) {
    return {perf: 0, yearly: []};
  }

  let monthlyPerfs: MonthlyPerf[] = [];
  let currentMonth = getMonth(firstPrice.ts);
  let currentMonthFirstPrice = firstPrice.price;
  let currentMonthLastPrice = firstPrice.price;

  const yearlyPerfs: YearlyPerf[] = [];
  let currentYear = currentMonth.year;
  let currentYearFirstPrice = currentMonthFirstPrice;
  let currentYearLastPrice = currentMonthFirstPrice;

  const globalPerf = (lastPrice.price - firstPrice.price) / firstPrice.price;

  for (const price of prices) {
    const month = getMonth(price.ts);
    currentMonthLastPrice = price.price;
    currentYearLastPrice = price.price;
    if (!sameMonth(currentMonth, month)) {
      monthlyPerfs.push({
        month: currentMonth.month,
        perf: (currentMonthLastPrice - currentMonthFirstPrice) / currentMonthFirstPrice,
      });
      currentMonth = month;
      currentMonthFirstPrice = price.price;
      currentMonthLastPrice = price.price;
    }
    if (month.year !== currentYear) {
      yearlyPerfs.push({
        year: currentYear,
        perf: (currentYearLastPrice - currentYearFirstPrice) / currentYearFirstPrice,
        monthly: monthlyPerfs,
      });
      currentYear = month.year;
      currentYearFirstPrice = price.price;
      currentYearLastPrice = price.price;
      monthlyPerfs = [];
    }
  }
  monthlyPerfs.push({
    month: currentMonth.month,
    perf: (currentMonthLastPrice - currentMonthFirstPrice) / currentMonthFirstPrice,
  });
  yearlyPerfs.push({
    year: currentYear,
    perf: (currentYearLastPrice - currentYearFirstPrice) / currentYearFirstPrice,
    monthly: monthlyPerfs,
  });

  return {perf: globalPerf, yearly: yearlyPerfs};
}

// export function perfs(prices: Price[]): GlobalPerf {
//   const monthly = monthlyPerfs(prices);
//   const firstMonth = monthly[0];
//   if (!firstMonth) {
//     return {perf: 0, yearly: []};
//   }
//   let globalPerf = 1;
//   let currentYear = firstMonth.month.year;
//   let currentYearlyPerf = firstMonth.perf + 1;
//   let currentMonthly = [firstMonth];
//   const yearlyPerfs: YearlyPerf[] = [];
//   for (const monthPerf of monthly.slice(1)) {
//     currentYearlyPerf *= monthPerf.perf + 1;
//     if (monthPerf.month.year === currentYear) {
//       currentMonthly.push(monthPerf);
//     } else {
//       yearlyPerfs.push({year: currentYear, perf: currentYearlyPerf - 1, monthly: currentMonthly});
//       globalPerf *= currentYearlyPerf;
//       currentYear = monthPerf.month.year;
//       currentYearlyPerf = monthPerf.perf + 1;
//       currentMonthly = [monthPerf];
//     }
//   }
//   yearlyPerfs.push({year: currentYear, perf: currentYearlyPerf - 1, monthly: currentMonthly});
//   globalPerf *= currentYearlyPerf;
//   return {yearly: yearlyPerfs, perf: globalPerf - 1};
// }
