import {FC} from 'react';
import {styled} from 'styled-components';

import {StockPrices, StockQuote} from '@shared/api/app_api';

import {Portfolio, PortfolioStock} from '@src/lib/portfolio';

export type PortfolioStockAndQuote = PortfolioStock & {
  realtime: Exclude<StockPrices['realtime'], undefined>;
  quote: StockQuote;
};
export type PortfolioAndQuotes = Omit<Portfolio, 'stocks'> & {stocks: PortfolioStockAndQuote[]};

interface PortfolioAllocationsProps {
  portfoliosAndQuotes: PortfolioAndQuotes[];
}

export const PortfolioAllocations: FC<PortfolioAllocationsProps> = props => {
  const {portfoliosAndQuotes} = props;
  const budget = 300000;
  let total = 0;
  const tableRows = [
    portfoliosAndQuotes.flatMap(p => {
      const portfolioBudget = budget / portfoliosAndQuotes.length;
      const stockBudget = portfolioBudget / p.stocks.length;
      let portfolioTotal = 0;
      const rows = p.stocks.map(s => {
        const qty = Math.round(stockBudget / s.realtime.price);
        const subTotal = s.realtime.price * qty;
        portfolioTotal += subTotal;
        return (
          <Row key={s.symbol}>
            <Cell>{s.quote.exchange}</Cell>
            <Cell>{s.symbol}</Cell>
            <Cell>{s.quote.name}</Cell>
            <CellNum>
              {s.realtime.price.toLocaleString(undefined, {
                style: 'currency',
                currency: s.realtime.currency,
                currencyDisplay: 'narrowSymbol',
              })}
            </CellNum>
            <CellNum>{qty}</CellNum>
            <CellNum>
              {subTotal.toLocaleString(undefined, {
                style: 'currency',
                currency: s.realtime.currency,
                currencyDisplay: 'narrowSymbol',
              })}
            </CellNum>
          </Row>
        );
      });
      total += portfolioTotal;
      return [
        <tr key={p.id}>
          <td colSpan={5}>
            <PortfolioCell>{p.label}</PortfolioCell>
          </td>
          <td>
            <PortfolioCell>
              {portfolioTotal.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'narrowSymbol',
              })}
            </PortfolioCell>
          </td>
        </tr>,
        rows,
      ];
    }),
  ];
  return (
    <table>
      <tbody>
        {tableRows}
        <tr key="total">
          <td colSpan={5}>
            <PortfolioCell>TOTAL</PortfolioCell>
          </td>
          <td>
            <PortfolioCell>
              {total.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
                currencyDisplay: 'narrowSymbol',
              })}
            </PortfolioCell>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

PortfolioAllocations.displayName = 'PortfolioAllocations';

const PortfolioCell = styled.div`
  font-size: 24px;
  font-weight: 600;
  padding: 16px 0 4px 0;
`;

const Cell = styled.td`
  max-width: 300px;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Row = styled.tr`
  &:nth-child(even) {
    background-color: #ffffff10;
  }
`;

const CellNum = styled(Cell)`
  text-align: right;
`;
