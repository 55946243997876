import {FC, MouseEventHandler, useCallback, useMemo, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {styled} from 'styled-components';

import {ButtonAsLink} from '@shared-web/components/core/button';
import {Spacing} from '@shared-web/components/core/spacing';

import {Perf} from '@src/components/perf';
import {perfs} from '@src/lib/perfs';
import {useStockData} from '@src/lib/stock_prices';

interface PriceChartProps {
  label: string;
  prices: {price: number; ts: number}[];
}

const dayDateFormat = {
  durationMs: 24 * 3600 * 1000,
  formatOptions: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  } as Intl.DateTimeFormatOptions,
};

/* eslint-disable @typescript-eslint/no-magic-numbers */
const currentYear = new Date().getFullYear();
interface Period {
  label: string;
  filter: (ts: number) => boolean;
}
const basePeriod = {label: 'All', filter: () => true};
const periods: Record<string, Period> = {
  '1m': {label: '1m', filter: (ts: number) => Date.now() - ts < 30.5 * 24 * 3600 * 1000},
  '3m': {label: '3m', filter: (ts: number) => Date.now() - ts < 3 * 30.5 * 24 * 3600 * 1000},
  '6m': {label: '6m', filter: (ts: number) => Date.now() - ts < 6 * 30.5 * 24 * 3600 * 1000},
  YTD: {label: 'YTD', filter: (ts: number) => new Date(ts).getFullYear() === currentYear},
  '1y': {label: '1y', filter: (ts: number) => Date.now() - ts < 365 * 24 * 3600 * 1000},
  '2y': {label: '2y', filter: (ts: number) => Date.now() - ts < 2 * 365 * 24 * 3600 * 1000},
  '3y': {label: '3y', filter: (ts: number) => Date.now() - ts < 3 * 365 * 24 * 3600 * 1000},
  All: basePeriod,
};

const months = [...new Array(12)].map((_, i) =>
  new Date(2000, i).toLocaleDateString(undefined, {month: 'short'})
);
/* eslint-enable @typescript-eslint/no-magic-numbers */

export const PriceChart: FC<PriceChartProps> = props => {
  const {label, prices} = props;
  const [period, setPeriod] = useState<Period>(basePeriod);
  const spyData = useStockData('SPY');

  const filteredPrices = useMemo(() => prices.filter(p => period.filter(p.ts)), [period, prices]);

  const handlePeriodClick = useCallback<MouseEventHandler>(evt => {
    const periodStr = evt.currentTarget.getAttribute('data-period');
    if (periodStr === null) {
      return;
    }
    const period = periods[periodStr];
    if (!period) {
      return;
    }
    setPeriod(period);
  }, []);

  const {perf, yearly} = useMemo(() => perfs(prices), [prices]);

  return (
    <Wrapper>
      <PeriodButtons>
        {Object.keys(periods).map(p => (
          <ButtonAsLink data-period={p} key={p} onClick={handlePeriodClick}>
            {p}
          </ButtonAsLink>
        ))}
      </PeriodButtons>
      <Spacing height={16} />
      <Line
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            x: {
              type: 'timescale',
              dateFormat: dayDateFormat.formatOptions,
              extraMsForPadding: dayDateFormat.durationMs / 2,
              options: {
                utc: false,
                ticksHeight: 7,
                subticksHeight: 3,
                group: {
                  line: {strokeStyle: '#cccccc'},
                  text: {font: '13px sans-serif', fillStyle: '#cccccc'},
                },
                ticks: {
                  height: 22,
                  line: {strokeStyle: '#cccccc'},
                  text: {
                    font: '11px sans-serif',
                    fillStyle: '#888888',
                  },
                },
              },
              // ticks: {z: 1}, // Control if the axis is draw under or over the chart bars
            },
            y: {
              type: 'linear',
              grid: {color: '#e5e5e5'},
            },
          },
          plugins: {legend: {display: false}},
        }}
        data={{
          labels: filteredPrices.map(p => p.ts),
          datasets: [
            {
              label,
              data: filteredPrices.map(p => p.price),
              borderColor: '#b16719',
              pointRadius: 0,
            },
            ...(spyData?.prices?.historic
              ? [
                  {
                    label: 'SPY',
                    data: spyData.prices.historic.map(
                      p => (p.price * (filteredPrices[0]?.price ?? 100)) / 100
                    ),
                    borderColor: '#ddd',
                    pointRadius: 0,
                  },
                ]
              : []),
          ],
        }}
      ></Line>
      <Table>
        <thead>
          <Row $even>
            <HeaderCell></HeaderCell>
            {months.map(m => (
              <HeaderCell key={m}>{m}</HeaderCell>
            ))}
            <HeaderCell></HeaderCell>
          </Row>
        </thead>
        <tbody>
          {yearly.map((p, yi) => (
            <Row $even={yi % 2 === 1} key={p.year}>
              <Cell>{p.year}</Cell>
              {months.map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <NumberCell key={i}>
                  <Perf perf={p.monthly.find(m => m.month === i)?.perf} />
                </NumberCell>
              ))}
              <YearCell>
                <Perf perf={p.perf} />
              </YearCell>
            </Row>
          ))}
          <Row $even={false}>
            <td></td>
            {months.map(m => (
              <td key={m}></td>
            ))}
            <YearCell>
              <Perf perf={perf} />
            </YearCell>
          </Row>
        </tbody>
      </Table>
    </Wrapper>
  );
};

PriceChart.displayName = 'PriceChart';

const Wrapper = styled.div`
  width: 100%;
  height: 700px;
  padding-bottom: 300px;
`;

const PeriodButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const Table = styled.table`
  border: solid 2px #b16719;
  border-top: none;
  margin-top: 16px;
`;
const Row = styled.tr<{$even: boolean}>`
  background-color: ${p => (p.$even ? '#00000022' : 'transparent')};
`;
const HeaderCell = styled.th`
  padding: 8px 12px;
  background: #b16719;
  color: #ffffff;
  vertical-align: middle;
`;
const Cell = styled.td<{$alignRight?: boolean}>`
  padding: 4px;
  white-space: nowrap;
  vertical-align: middle;
  ${p => p.$alignRight && 'text-align: right;'}
`;
const NumberCell = styled(Cell)`
  text-align: right;
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
const YearCell = styled(NumberCell)`
  background-color: #ffffff11;
`;
