export const APP_BACKEND_FUNCTION_NAME = 'portfolio-app_backend' as string;
export const APP_BACKEND_URL = 'https://portfolio.matthis.link/' as string;
export const APP_BACKEND_ROLE_ARN =
  'arn:aws:iam::982619301361:role/portfolio-app_backend-role' as string;
export const APP_FRONTEND_CLOUDFRONT_DOMAIN_NAME = 'd240xdr2cgtinb.cloudfront.net' as string;
export const REGION = 'eu-west-3' as string;
export const ACCOUNT_ID = '982619301361' as string;
export const ADMINISTRATOR_ROLE_ARN =
  'arn:aws:iam::982619301361:role/aws-reserved/sso.amazonaws.com/AWSReservedSSO_AdministratorAccess_b208c5425ea07e0d' as string;
export const CODE_BUCKET = 'portfolio-code-20240718043858946300000002' as string;
export const NODE_ENV = 'production' as string;
