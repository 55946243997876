import {ChangeEvent, FC, MouseEventHandler, useCallback} from 'react';
import {styled} from 'styled-components';

import {EditableInput} from '@shared-web/components/core/editable_input';
import {Input} from '@shared-web/components/core/input';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {trashIcon} from '@shared-web/components/icons/trash_icon';

import {PortfolioInfo} from '@src/components/portfolio_info';
import {StockLine} from '@src/components/stock_line';
import {updatePortfolios, usePortfolios} from '@src/lib/portfolio';
import {RedButton} from '@src/theme';

interface PortfolioProps {
  portfolioId: string;
}

export const Portfolio: FC<PortfolioProps> = props => {
  const {portfolioId} = props;
  const portfolio = usePortfolios().find(p => p.id === portfolioId);
  const {stocks} = portfolio ?? {};

  const handleStockSymbolChange = useCallback(
    (symbol: string, el: HTMLDivElement) => {
      const indexStr = el.getAttribute('data-index');
      if (indexStr === null) {
        return;
      }
      const index = parseFloat(indexStr);
      updatePortfolios(all =>
        all.map(p => {
          if (p.id !== portfolioId) {
            return p;
          }
          const newStocks = [...p.stocks];
          newStocks[index] = {symbol, quantity: newStocks[index]?.quantity ?? 1};
          return {...p, stocks: newStocks};
        })
      );
    },
    [portfolioId]
  );

  const handleStockQuantityChange = useCallback(
    (quantity: number, evt: ChangeEvent<HTMLInputElement>) => {
      const indexStr = evt.currentTarget.getAttribute('data-index');
      if (indexStr === null) {
        return;
      }
      const index = parseFloat(indexStr);
      updatePortfolios(all =>
        all.map(p => {
          if (p.id !== portfolioId) {
            return p;
          }
          const newStocks = [...p.stocks];
          newStocks[index] = {quantity, symbol: newStocks[index]?.symbol ?? ''};
          return {...p, stocks: newStocks};
        })
      );
    },
    [portfolioId]
  );

  const handleDeleteStockClick = useCallback<MouseEventHandler>(
    evt => {
      const indexStr = evt.currentTarget.getAttribute('data-index');
      if (indexStr === null) {
        return;
      }
      const index = parseFloat(indexStr);
      updatePortfolios(all =>
        all.map(p => {
          if (p.id !== portfolioId) {
            return p;
          }
          const newStocks = [...p.stocks];
          newStocks.splice(index, 1);
          return {...p, stocks: newStocks};
        })
      );
    },
    [portfolioId]
  );

  const handleDeletePortfolioClick = useCallback(() => {
    updatePortfolios(current => current.filter(p => p.id !== portfolioId));
  }, [portfolioId]);

  const handleLabelChange = useCallback(
    (newLabel: string) => {
      updatePortfolios(current =>
        current.map(p => (p.id === portfolioId ? {...p, label: newLabel} : p))
      );
    },
    [portfolioId]
  );

  if (!stocks || !portfolio) {
    return <></>;
  }

  return (
    <Wrapper>
      <Header>
        <EditableInput
          value={portfolio.label}
          onChange={handleLabelChange}
          overrides={{fontSize: 20}}
          inputProps={{width: '100%'}}
          textWidth={'100%'}
        />
        <RedButton onClick={handleDeletePortfolioClick}>Delete portfolio</RedButton>
      </Header>
      {stocks.map((s, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <StockWrapper key={i}>
          <StockLine data-index={i} symbol={s.symbol} onStockChange={handleStockSymbolChange} />
          <StockForm>
            <div>
              <Input<number>
                data-index={i}
                value={s.quantity}
                syncStateWithEvent={handleStockQuantityChange}
                fromString={parseFloat}
                asString={String}
                width={64}
                label="Qty"
                labelPosition="center"
              />
            </div>
            <RedButton data-index={i} onClick={handleDeleteStockClick}>
              <SvgIcon icon={trashIcon} size={22} color="#fff" />
            </RedButton>
          </StockForm>
        </StockWrapper>
      ))}
      <StockWrapper key="new">
        <StockLine
          key={`new-${stocks.length}`}
          data-index={stocks.length}
          onStockChange={handleStockSymbolChange}
        />
        <StockForm>
          <div>
            <Input value={''} width={64} label="Qty" labelPosition="center" disabled />
          </div>
          <RedButton disabled>
            <SvgIcon icon={trashIcon} size={22} color="#fff" />
          </RedButton>
        </StockForm>
      </StockWrapper>
      <PortfolioInfo portfolios={[portfolio]} />
    </Wrapper>
  );
};

Portfolio.displayName = 'Portfolio';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 12px;
  margin: 16px 0 12px 0;
  background: #ffffff09;
  & > button {
    flex-shrink: 0;
  }
`;

const StockWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const StockForm = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;
