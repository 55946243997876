import {FC, useMemo} from 'react';
import {styled} from 'styled-components';

import {
  PortfolioAllocations,
  PortfolioAndQuotes,
  PortfolioStockAndQuote,
} from '@src/components/portfolio_allocations';
import {usePortfolios} from '@src/lib/portfolio';
import {useAllStockData} from '@src/lib/stock_prices';

interface AllocationPageProps {}

export const AllocationPage: FC<AllocationPageProps> = () => {
  const portfolios = usePortfolios();
  const stockData = useAllStockData();

  const portfoliosAndQuotes = useMemo(() => {
    const res: PortfolioAndQuotes[] = [];
    for (const p of portfolios) {
      const stocks: PortfolioStockAndQuote[] = [];
      for (const s of p.stocks) {
        if (s.quantity === 0) {
          continue;
        }
        const data = stockData.get(s.symbol);
        const realtime = data?.prices?.realtime;
        const quote = data?.quote;
        if (!realtime || !quote) {
          return undefined;
        }
        stocks.push({...s, realtime, quote});
      }
      res.push({...p, stocks});
    }
    return res;
  }, [portfolios, stockData]);

  if (!portfoliosAndQuotes) {
    return <Wrapper>Loading prices...</Wrapper>;
  }

  return (
    <Wrapper>
      <PortfolioAllocations portfoliosAndQuotes={portfoliosAndQuotes} />
    </Wrapper>
  );
};

AllocationPage.displayName = 'AllocationPage';

const Wrapper = styled.div`
  margin: auto;
  padding: 32px;
`;
