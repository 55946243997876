import {daysInWeek, hoursInDay} from '@shared-web/components/core/chart/time_axis/time_constants';

export type PeriodFormatter = (date: Date, utc: boolean) => string;

function dateTimeFormatter(options: Intl.DateTimeFormatOptions): PeriodFormatter {
  return (date: Date, utc: boolean) =>
    new Intl.DateTimeFormat('default', {...options, timeZone: utc ? 'UTC' : undefined}).format(
      date
    );
}

function paddedNumber(value: number): string {
  return value >= 10 ? String(value) : `0${value}`;
}

export const shortYearDateTimeFormatter: PeriodFormatter = dateTimeFormatter({year: '2-digit'});
export const yearDateTimeFormatter: PeriodFormatter = dateTimeFormatter({year: 'numeric'});
export const shortMonthDateTimeFormatter: PeriodFormatter = dateTimeFormatter({month: 'short'});
export const longMonthDateTimeFormatter: PeriodFormatter = dateTimeFormatter({month: 'long'});
export const dayDateTimeFormatter: PeriodFormatter = dateTimeFormatter({day: '2-digit'});
export const hourDateTimeFormatter: PeriodFormatter = date => paddedNumber(date.getHours());
export const minuteDateTimeFormatter: PeriodFormatter = date => paddedNumber(date.getMinutes());
export const secondDateTimeFormatter: PeriodFormatter = date => paddedNumber(date.getSeconds());

export const shortMonthAndYearFormatter: PeriodFormatter = (date, utc) =>
  `${shortMonthDateTimeFormatter(date, utc)} ${yearDateTimeFormatter(date, utc)}`;
export const longMonthAndYearFormatter: PeriodFormatter = (date, utc) =>
  `${longMonthDateTimeFormatter(date, utc)} ${yearDateTimeFormatter(date, utc)}`;

export const weekFormatter: PeriodFormatter = (date, utc) => {
  const d = utc
    ? new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
    : new Date(date.getFullYear(), date.getMonth(), date.getDate());
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  if (utc) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    d.setUTCDate(d.getUTCDate() + 4 - ((d.getUTCDay() + daysInWeek) % daysInWeek));
  } else {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    d.setDate(d.getDate() + 4 - ((d.getDay() + daysInWeek) % daysInWeek));
  }
  // Get first day of year
  const yearStart = utc
    ? new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    : new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  const weekNumber = Math.ceil(
    ((d.getTime() - yearStart.getTime()) / (hoursInDay * 3600 * 1000) + 1) / daysInWeek
  );
  return weekNumber >= 10 ? `W-${weekNumber}` : `W-${weekNumber}`;
};

export const hourWithSuffixTimeFormatter: PeriodFormatter = (date, utc) =>
  `${utc ? date.getUTCHours() : date.getHours()}h`;
export const noZeroHourTimeFormatter: PeriodFormatter = (date, utc) =>
  (utc ? date.getUTCHours() : date.getHours()) === 0 ? '' : hourWithSuffixTimeFormatter(date, utc);
export const noZeroMinuteTimeFormatter: PeriodFormatter = (date, utc) =>
  (utc ? date.getUTCMinutes() : date.getMinutes()) === 0 ? '' : minuteDateTimeFormatter(date, utc);
export const noZeroSecondTimeFormatter: PeriodFormatter = (date, utc) =>
  (utc ? date.getUTCSeconds() : date.getSeconds()) === 0 ? '' : secondDateTimeFormatter(date, utc);

export const hourWithSuffixAndDayAndMonthTimeFormatter: PeriodFormatter = (date, utc) =>
  `${dayDateTimeFormatter(date, utc)} ${shortMonthDateTimeFormatter(
    date,
    utc
  )}, ${hourWithSuffixTimeFormatter(date, utc)}`;

export const dayAndShortMonthFormatter: PeriodFormatter = (date, utc) =>
  `${dayDateTimeFormatter(date, utc)} ${shortMonthDateTimeFormatter(date, utc)}`;
export const dayAndShortMonthAndYearFormatter: PeriodFormatter = (date, utc) =>
  `${dayDateTimeFormatter(date, utc)} ${shortMonthDateTimeFormatter(
    date,
    utc
  )}, ${yearDateTimeFormatter(date, utc)}`;
export const dayAndLongMonthFormatter: PeriodFormatter = (date, utc) =>
  `${dayDateTimeFormatter(date, utc)} ${longMonthDateTimeFormatter(date, utc)}`;
export const dayAndLongMonthAndYearFormatter: PeriodFormatter = (date, utc) =>
  `${dayDateTimeFormatter(date, utc)} ${longMonthDateTimeFormatter(
    date,
    utc
  )}, ${yearDateTimeFormatter(date, utc)}`;

export const hourAndMinuteFormatter: PeriodFormatter = (date, utc) =>
  `${utc ? date.getUTCHours() : date.getHours()}h${minuteDateTimeFormatter(date, utc)}`;

export const hourAndMinuteAndSecondFormatter: PeriodFormatter = (date, utc) =>
  `${utc ? date.getUTCHours() : date.getHours()}:${minuteDateTimeFormatter(
    date,
    utc
  )}:${secondDateTimeFormatter(date, utc)}`;

export const millisecondFormatter: PeriodFormatter = (date, utc) =>
  `${utc ? date.getUTCMilliseconds() : date.getMilliseconds()}ms`;
